import { useEffect, useState } from 'react'


type UseExpirationProps = {
  timeLeft: number
  onExpired?: () => void
}

// return true if an offer is expired
const useExpiration = ({ timeLeft, onExpired }: UseExpirationProps) => {
  const [ isExpired, setExpired ] = useState(!timeLeft || timeLeft <= 0)

  useEffect(() => {
    if (timeLeft <= 0) {
      setExpired(true)
      return
    }

    // it helps if state should be reset
    setExpired(false)

    // Max available timeout is 2 ** 31 - 1 or about 24.855 days
    const maxTimeout = 2 ** 31 - 1

    const timeout = setTimeout(() => {
      setExpired(true)

      if (typeof onExpired === 'function') {
        onExpired()
      }
    }, Math.min(timeLeft, maxTimeout))

    return () => {
      clearTimeout(timeout)
    }
  }, [ timeLeft, onExpired ])

  return isExpired
}


export default useExpiration
