import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { constants } from 'helpers'
import links from 'links'


const narvarCourierToParcellab = {
  // 'ups',
  // 'usps',
  'ups_mi': 'ups-mi',
  'asendia': 'asendia-us',
  'dhlglobal': 'dhl-gm',
}

const useTrackingUrl = (originalUrl?: string) => {
  const isReplaceNarvarLinksEnabled = useFeatureIsOn(constants.features.webReplaceNarvarLinks)

  // if the redirect is disabled, then we should rewrite all narvar links to our /tracking page
  if (isReplaceNarvarLinksEnabled && originalUrl && originalUrl.includes('.narvar.')) {
    const url = new URL(originalUrl)
    let narvalCourier = url.pathname.split('/').at(-1) // gets the last segment of url's pathname

    return `${links.tracking}?${new URLSearchParams({
      courier: narvarCourierToParcellab[narvalCourier] || narvalCourier,
      trackingNo: url.searchParams.get('tracking_numbers'),
    })}`
  }

  return originalUrl
}

export default useTrackingUrl
