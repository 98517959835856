import { useState, useEffect, useCallback } from 'react'
import localStorage from 'local-storage'


const useSessionCount = (storageKey: string) => {
  const [ sessionCount, setSessionCount ] = useState(0)

  const updateSessionCount = useCallback(() => {
    try {
      const initialCount = localStorage.getItem<number>(storageKey) || 0
      const nextCount = initialCount + 1

      localStorage.setItem(storageKey, nextCount)

      setSessionCount(nextCount)
    }
    catch {
      setSessionCount(0)
    }
  }, [ storageKey ])

  useEffect(() => {
    updateSessionCount()
  }, [ updateSessionCount ])

  return { sessionCount }
}

export default useSessionCount
