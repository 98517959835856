/*

  returns "forceUpdate" method to invoke rerender of component programmatically. This hook is a sugar to not write
  copycat of

  const [ _, setState ] = useState(0)

  setState((v) => ++v)


  Usage:

  const forceUpdate = useForceUpdate()

  const handleClick = useCallback(() => {
    // do something
    forceUpdate()
  }, [])

 */

import { useState, useCallback } from 'react'


const useForceUpdate = (): [ number, () => void ] => {
  const [ state, setState ] = useState(0)

  const forceUpdate = useCallback(() => {
    setState((v) => v + 1)
  }, [])

  return [ state, forceUpdate ]
}


export default useForceUpdate
