import { useEffect, useRef } from 'react'


type UseScrollingFrameProps = {
  inverted?: boolean
  isVisible: boolean
}

const HORIZONTAL_OFFSET = 16
const VERTICAL_OFFSET = 16

// TODO: use this hook for landing page
const useScrollingFrame = ({ inverted, isVisible }: UseScrollingFrameProps) => {
  const frameRef = useRef(null)

  useEffect(() => {
    if (!isVisible) {
      return
    }

    let animationFrameId
    let scrollTop
    let blockRect
    const block = frameRef.current

    const frame = () => {
      const blockOffsetTop = blockRect.top + scrollTop
      const scrollWidth = blockRect.width - window.innerWidth + HORIZONTAL_OFFSET * 2
      // for block in the beginning of the page, we start from 0, but for other blocks we start from the top of the block
      const scrollStart = (blockOffsetTop < window.innerHeight ? 0 : blockOffsetTop - window.innerHeight)
      const scrollHeight = (blockOffsetTop < window.innerHeight ? blockOffsetTop : window.innerHeight) - VERTICAL_OFFSET

      animationFrameId = null

      const percent = (scrollTop - scrollStart) / scrollHeight
      const value = scrollWidth * Math.min(1, Math.max(0, percent))

      block.style.transform = `translateX(${inverted ? value - scrollWidth : -value}px)`
      block.style.willChange = 'transform'
    }

    const handleScroll = () => {
      scrollTop = window.scrollY
      blockRect = block.getBoundingClientRect()

      if (animationFrameId) {
        return
      }

      animationFrameId = requestAnimationFrame(frame)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
      cancelAnimationFrame(animationFrameId)
    }
  }, [ inverted, isVisible ])

  return frameRef
}

export default useScrollingFrame
