import { useCallback, useEffect } from 'react'
import { useDevice } from 'device'
import { useEntry, useSticky } from 'intersection-observer'


export type UseStickyBetweenProps = {
  stickyClass: string | string[]
  bottomObserverProps?: IntersectionObserverInit
  onlyMobile?: boolean
  topObserverProps?: IntersectionObserverInit
}

export type UseStickyBetweenResult = [
  containerRef: React.RefObject<any>,
  stickyElementRef: React.RefObject<any>,
  topTriggerRef: React.RefObject<any>,
  bottomTriggerRef: React.RefObject<any>,
  setSticky: (isSticky: boolean) => void,
  refresh: () => void
]

const useStickyBetween = ({ bottomObserverProps, onlyMobile = true, stickyClass, topObserverProps }: UseStickyBetweenProps): UseStickyBetweenResult => {
  const { isMobile } = useDevice()

  const [ containerRef, stickyElementRef, setSticky ] = useSticky({ stickyClass })
  const [ topTriggerRef, topEntry, unobserveTop, refreshTop ] = useEntry({ observerProps: topObserverProps })
  const [ bottomTriggerRef, bottomEntry, unobserveBottom, refreshBottom ] = useEntry({ observerProps: bottomObserverProps })

  const refresh = useCallback(() => {
    refreshTop()
    refreshBottom()
  }, [ refreshTop, refreshBottom ])

  useEffect(() => {
    if (!topEntry || !bottomEntry) {
      return
    }

    if (onlyMobile && !isMobile) {
      return
    }

    if (topEntry.isIntersecting) {
      return setSticky(true)
    }

    if (bottomEntry.isIntersecting) {
      return setSticky(false)
    }

    const windowInnerHeight = window.innerHeight

    return setSticky(topEntry.boundingClientRect.top < windowInnerHeight && bottomEntry.boundingClientRect.top > windowInnerHeight)
  }, [ bottomEntry, isMobile, onlyMobile, setSticky, topEntry ])

  return [ containerRef, stickyElementRef, topTriggerRef, bottomTriggerRef, setSticky, refresh ]
}

export default useStickyBetween
