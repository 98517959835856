import { useContext } from 'react'

import { GlobalLoaderContext } from 'containers/App/components/GlobalLoaderProvider/GlobalLoaderProvider'


const useGlobalLoader = () => {
  const context = useContext(GlobalLoaderContext)

  if (!context) {
    throw new Error('useGlobalLoader must be used within a GlobalLoaderProvider')
  }

  return context
}


export default useGlobalLoader
