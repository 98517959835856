import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'


const useFlow = () => {
  const flowName = cookieStorage.getItem(constants.cookieNames.flowName)?.toLowerCase()
  const utmMedium = cookieStorage.getItem(constants.cookieNames.utmMediumBeforeSubscription)
  const utmSource = cookieStorage.getItem(constants.cookieNames.utmSourceBeforeSubscription)

  return {
    isCoFlow: flowName === 'coflow', // only stoyo
    isOrgFlow: flowName === 'orgflow', // @deprecated (bcs all orgflow urls moved on /free-trial page)
    isEmailFlow: utmSource === 'email',
    isFacebookFlow: flowName === 'facebookflow', // all facebook marketing
    isInfluencerFlow: utmMedium === 'influencer' || utmSource === 'youtube', // tiktok, instagramm, youtube from influencers
    isOrganicFlow: !utmSource, // utm_source should always be with marketing flows
  }
}


export default useFlow
