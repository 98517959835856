import { useEffect, useState, useRef } from 'react'
import logger from 'logger'


type UseScriptReady = (scriptName: string, timeout?: number) => boolean

const useGTMScriptReady: UseScriptReady = (scriptName, timeout = 60000) => {
  const [ isReady, setReady ] = useState(typeof window !== 'undefined' && window[scriptName] !== undefined)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout>(null)

  useEffect(() => {
    if (typeof window === 'undefined') {
      logger.debug(`[GTM Script] Window object not available. ${scriptName}`)
      return
    }

    if (window[scriptName]) {
      logger.info(`[GTM Script] Script ${scriptName} is immediately ready.`)
      setReady(true)
      return
    }

    intervalRef.current = setInterval(() => {
      if (window[scriptName]) {
        logger.info(`[GTM Script] Script ${scriptName} is loaded.`)
        setReady(true)
        clearInterval(intervalRef.current)
      }
    }, 300)

    timeoutRef.current = setTimeout(() => {
      if (!window[scriptName]) {
        logger.warn(`[GTM Script] Script ${scriptName} failed to load within ${timeout} ms.`)
      }

      clearInterval(intervalRef.current)
    }, timeout)

    return () => {
      clearInterval(intervalRef.current)
      clearTimeout(timeoutRef.current)
    }
  }, [ scriptName, timeout ])

  return isReady
}

export default useGTMScriptReady
