import { useState, useEffect } from 'react'


// This functionality is needed to properly hide forms or other elements during server rendering.
// Since we are using hydrate(), the client's render must match the one that came from the SSR.
// https://reactjs.org/docs/react-dom.html#hydrate
const useSSR = () => {
  const [ isSSR, setSSR ] = useState(true)

  useEffect(() => {
    setSSR(false)
  }, [])

  return isSSR
}


export default useSSR
