import { useMemo, useRef } from 'react'


// if you want to execute a function only once, use can use this hook
const useOnce = (callback: () => void, deps: any[] = []) => {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useMemo(() => {
    callbackRef.current()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}


export default useOnce
