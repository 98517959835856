import { useEffect, useState } from 'react'
import logger from 'logger'
import dayjs from 'date'


// The API is taken from ParcelLab JS plugin https://github.com/parcelLab/parcelLab-js-plugin/tree/main
// Please, note that this prediction API is not described on their doc site and a described tracking-details API is not working in fact

const cache: { [key: string]: string | undefined } = {}

const fetchDeliveryDate = async (courier: string, trackingNumber: string) => {
  if (!courier || !trackingNumber) {
    return
  }

  const cacheKey = `${courier}-${trackingNumber}`
  if (cache[cacheKey]) {
    return cache[cacheKey]
  }

  try {
    const response = await fetch(`https://api.parcellab.com/prediction/parcel/?courier=${courier}&tno=${trackingNumber}`)
    if (response.status !== 200) {
      return
    }

    const responseData = await response.json()
    const predictionData = responseData?.find((item) => item?.prediction?.type === 'prediction')
    const predictionDateString = predictionData?.prediction?.raw?.earliest || predictionData?.prediction?.raw?.latest

    cache[cacheKey] = predictionDateString

    return predictionDateString
  }
  catch (error) {
    logger.error(error)
  }
}

// TODO: Use "courier" and "tracking number" directly from backend
// instead of url parsing if CRO-477 test wins and "courier" filed is added on backend
type Options = {
  skip: boolean
}

const useTrackingEstimatedDelivery = (trackingUrl: string, options?: Options) => {
  const [ deliveryDate, setDeliveryDate ] = useState<string>(undefined)


  useEffect(() => {
    if (!trackingUrl || options?.skip) {
      return
    }

    void (async () => {
      try {
        const url = new URL(trackingUrl)
        const courier = url.searchParams.get('courier')
        const trackingNumber = url.searchParams.get('trackingNo')

        const deliveryDate = await fetchDeliveryDate(courier, trackingNumber)
        const formattedDate = deliveryDate ? dayjs(deliveryDate).format('MMM. DD') : undefined

        setDeliveryDate(formattedDate)
      }
      catch {
        return
      }
    })()
  }, [ trackingUrl, options?.skip ])

  return deliveryDate
}

export default useTrackingEstimatedDelivery
