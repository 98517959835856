import { useCallback, type Ref, type MutableRefObject } from 'react'


type RefFunc<T> = (instance: T | null) => void

// helps to combine local ref and forwardedOne
const useCombinedRefs = <T>(refs: Ref<T>[]): RefFunc<T> => {
  return useCallback<RefFunc<T>>((instance) => {
    refs.forEach((ref) => {
      if (!ref) {
        return
      }

      if (typeof ref === 'function') {
        return ref(instance)
      }

      (ref as MutableRefObject<T>).current = instance
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs)
}


export default useCombinedRefs
