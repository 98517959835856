import { useState, useEffect } from 'react'
import logger from 'logger'


let loadedScripts = {}

// ATTN for tests usage only!
export const clearLoadedScripts = () => loadedScripts = {}

const loadScript = (src: string, async: boolean = true, retries: number = 5): Promise<void> => {
  if (loadedScripts[src] === true) {
    return Promise.resolve()
  }

  if (loadedScripts[src]) {
    return loadedScripts[src]
  }

  const waitForScript = () => new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')

    script.async = async
    script.src = src

    script.onerror = (error) => {
      if (script.parentNode) {
        script.parentNode.removeChild(script)
      }

      reject(error)
    }

    script.onload = () => {
      logger.info(`Script "%s" loaded`, src)
      resolve()
    }

    document.head.appendChild(script)
  })

  loadedScripts[src] = (async () => {
    for (let attempt = 0; attempt <= retries; attempt++) {
      try {
        await waitForScript()
        // required for setting correct status of "isLoaded" when the hook is rendered first time.
        loadedScripts[src] = true
        return
      }
      catch (error) {
        if (attempt >= retries) {
          throw error
        }

        logger.info('Script "%s" loading error, retry attempt %i', src, attempt + 1)
        await new Promise((resolve) => setTimeout(resolve, 500 + attempt * 350))
      }
    }
  })()

  return loadedScripts[src]
}

type State = {
  isLoading: boolean
  error: Error
}

type Opts = {
  async?: boolean // load scripts asynchronously (don't block other js execution)
  retries?: number // number of retries to load the script
  skip?: boolean // pass true if you want to disable loading
}

type Result = {
  isScriptLoading: boolean
  scriptLoadingError: Error
  isScriptLoaded: boolean
}

const useScriptLoader = (src: string, opts?: Opts): Result => {
  const { async, retries, skip } = opts || {}

  const isLoaded = loadedScripts[src] === true // need to check exactly for "true" value because value can be an unresolved Promise

  const [ state, setState ] = useState<State>({
    isLoading: !isLoaded,
    error: null,
  })

  const { isLoading, error } = state

  useEffect(() => {
    if (isLoaded || skip) {
      return
    }

    let isMounted = true

    loadScript(src, async, retries)
      .then(() => {
        if (isMounted) {
          setState({ isLoading: false, error: null })
        }
      })
      .catch((err) => {
        if (isMounted) {
          logger.error(error, `Script "%s" load error`, src)
          setState({ isLoading: false, error: err })
        }
      })

    return () => {
      isMounted = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isScriptLoading: isLoading,
    scriptLoadingError: error,
    isScriptLoaded: !isLoading && !error,
  }
}


export default useScriptLoader
