import logger from 'logger'
import type { AbTestName } from 'helpers/constants/abTests'
import { useAbFt } from 'modules/user'


const loggedItems = new Set()

const useAb = (name: AbTestName) => {
  const { abTests } = useAbFt()

  const value = abTests[name]?.value

  if (__CLIENT__) {
    if (typeof value === 'undefined' && !loggedItems.has(name)) {
      loggedItems.add(name)
      logger.warn(`AbTest "%s" is not defined`, name)
    }
  }

  return value
}


export default useAb
