import React, { useEffect, useState } from 'react'
import { throttle } from 'throttle-debounce'


export type TriggerFirePosition = 'top-0' | 'top-height'

export type UseStickyBeforeFooterRequiredProps = AtLeastOne<{
  minimalScrollOffset: number
  triggerRef: React.RefObject<HTMLElement>
}>

export type UseStickyBeforeFooterProps = {
  skip?: boolean
  triggerFirePosition?: TriggerFirePosition
} & UseStickyBeforeFooterRequiredProps

const checkTriggerRect = (triggerRect: DOMRect, triggerFirePosition: TriggerFirePosition) => {
  if (triggerFirePosition === 'top-height') {
    return triggerRect.top <= window.innerHeight
  }

  // top-0 is default
  return triggerRect.top < 0
}

const useStickyBeforeFooter = ({ skip, minimalScrollOffset, triggerFirePosition = 'top-0', triggerRef }: UseStickyBeforeFooterProps) => {
  const [ isVisible, setVisibility ] = useState(false)

  useEffect(() => {
    if (skip) {
      return
    }

    let prevIsVisible = false
    const isOffsetWay = typeof minimalScrollOffset === 'number' && minimalScrollOffset >= 0

    const handleScroll = throttle(() => {
      try {
        if (!isOffsetWay && !triggerRef.current) {
          return
        }

        const isTopTriggerFired = isOffsetWay
          ? window.scrollY >= minimalScrollOffset
          : checkTriggerRect(triggerRef.current.getBoundingClientRect(), triggerFirePosition)

        // hide when footer is visible
        const isFooterVisible = document.getElementById('footer')?.getBoundingClientRect().top < window.innerHeight

        const isVisible = isTopTriggerFired && !isFooterVisible

        if (isVisible !== prevIsVisible) {
          setVisibility(isVisible)
          prevIsVisible = isVisible
        }
      }
      catch (error) {
        console.error(error)
      }
    }, 200)

    window.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ skip, triggerRef, triggerFirePosition, minimalScrollOffset ])

  return isVisible
}


export default useStickyBeforeFooter
