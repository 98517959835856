import logger from 'logger'
import type { FeatureName } from 'helpers/constants/features'
import { useAbFt } from 'modules/user'


const loggedItems = new Set()

const useFt = (name: FeatureName) => {
  const { features } = useAbFt()

  if (!name) {
    return
  }

  const value = features[name]

  if (__CLIENT__) {
    if (typeof value === 'undefined' && !loggedItems.has(name)) {
      loggedItems.add(name)
      logger.warn(`Feature "%s" is not defined`, name)
    }
  }

  return value
}


export default useFt
